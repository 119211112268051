import { Link } from "react-router-dom";
function PreRecordingClasses() {
    return (
        <>
            {/* <div className="dempp" style={{backgroundImage: "url('/assets/img/hero/belly-dance.jpg')", backgroundSize: 'cover'}}>
      <h2>PRE-RECORDED CLASSES</h2>
         </div> */}
            <div className="container my-5">
                <div className="dance bgring1">
                    <div className="row">
                        <h3 style={{ textAlign: 'center' }}>This Page Will Be Updated Soon. <br />
                            Please Stay Tuned to have all the updates.</h3>
                    </div>
                </div>
            </div>
        </>
    )
}
export default PreRecordingClasses;